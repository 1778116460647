<template>
  <div class="banner">
    <div class="banner-container">
      <div class="banner-left">
        <div class="left-text">
          <div class="title">
            SMARTCAT嘿猫嗨购
          </div>
          <div class="title">
            全球集运建站平台
          </div>
          <div class="smallTitle">
            新集运 新机遇 新商机
          </div>
          <div class="botBtn">
            <button class="btn" @click="showContact">
              <span class="text">免费建站</span>
            </button>
          </div>
        </div>
      </div>
      <div class="banner-right">
        <div class="pic">
          <img class="img" :src=src alt="">
        </div>
      </div>
    </div>
    <div>
      <div v-if="dialogVisible" class="dialog-overlay">
        <div class="dialog-container">
          <div class="topImg">
            <img class="bgImg" src="@/assets/smartcat/popUpBg.png" alt="">
          </div>
          <div class="contact">
            <img class="contactImg" src="@/assets/smartcat/weChat.jpg" alt="">
          </div>
          <div>
            <p class="text">添加工作人员企微，申请免费体验试用</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button class="botBtn" @click="closeDialog">X</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src:{
      type:String,
      require:true
    }
  },
  name: 'homeComAbout',
  data () {
    return {
       dialogVisible: false,
    }
  },
  methods: {
    closeDialog () {
      this.dialogVisible = false;
    },
    showContact(){
      // this.dialogVisible = true
       this.$router.push({
        path:'/login'
       })
    },
    showDialogs() {
      console.log(111111111111);
    },
    }
}
</script>

<style lang="scss" scoped="scoped">
.banner {
  background-color: #f8f8f8;

  .banner-container {
    display: flex;
    width: 1200px;
    margin: auto;
    position: relative;

    .banner-left {
      position: absolute;
      z-index: 999;

      .left-text {
        margin-top: 80px;

        .title {
          font-size: 60px;
          letter-spacing: 0px;
          font-weight: bold;
          line-height: 100px;
          color: rgba(0, 0, 0, 1);
          font-family: PingFangSC, sans-serif;
        }

        .smallTitle {
          margin-top: 20px;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 39.2px;
          color: rgba(0, 0, 0, 1);
          font-family: PingFangSC-Bold, sans-serif;
        }

        .botBtn {
          margin-top: 50px;
          .btn {      
            width: 160px;
            height: 44px;
            border-radius: 22px;
            background-color: #fdd000;
            border-color: #fdd000 !important;
            border: none;
            cursor: pointer;

            .text {
              font-family: PingFangSC-Regular, sans-serif;
              font-size: 18px;
              font-weight: 500;
              color: rgba(0, 0, 0, 1);
              text-align: center;
            }
          }
          .btn:hover {
              background: #000;
              .text {
                color: #fff;
              }
            }
        }
      }

    }

    .banner-right {
      width: 750px;
      height: 500px;

      .pic {
        .img {
          width: 750px;
          height: 500px;
          margin-left: 450px;
        }
      }
    }

  }
  .dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  background-color: #fff;
  border-radius: 12px;
  background: linear-gradient(to top, #fff, #fff5cb);

  .topImg {
    margin-top: -70px;
    .bgImg {
      width: 268px;
      height: 126px;
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background-color: #fff5c9;
    border-radius: 15px;
    margin-top: 40px;
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    .contactImg {
      padding: 4px;
      background-color: #fff;
      width: 140px;
      height: 140px;
      border-radius: 8px;
    }
  }
  .text {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 25.2px;
    color: rgba(102, 102, 102, 1);
  }
}
}
.dialog-footer {
  text-align: right;
  margin-top: 20px;

  .botBtn {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }
}
}

</style>
